#select-questions{
    background-image: url('../assets/background.jpeg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-around;
    height: 100vh;
    width: 100vw;

    section {
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 5px;
        margin-top: 50px;
        padding-left: 2rem;
        padding-right: 2rem;
        height: 500px;
        width: 480px;        
    }

    h1 {
        font-size: 2.5rem;
        font-weight: bold;
        text-align: center;
        color: white;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .select-button-container {
        width: 100%;
    }

    a.select-button {
        background-color:green;
        border-radius: 30px;
        color: white;
        font-size: 1.5rem;
        font-weight: bold;
        text-decoration: none;
        display: block;
        cursor: pointer;
        padding: 0.5rem;
        text-align: center;
        margin-top: 2rem;
        transition: 0.3s linear all;
    }

    a.select-button:hover {
        background-color: lighten(green, 20%);
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    }
}