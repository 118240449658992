#card {
    background-image: url('../assets/background.jpeg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-around;
    height: 100vh;
    width: 100vw;

    .question {
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        border-radius: 5px;
        margin-top: 50px;
        padding-left: 2rem;
        padding-right: 2rem;
        height: 400px;
        width: 400px;
    }

    .question_number{
        color: white;
        font-size: small;
    }

    h2 {
        text-align: center;
        font-weight: bold;
        font-size: 1.5rem;
        color: white;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
    }

    .radio-container{
        background-color: rgba(61, 59, 59, 0.7);
        margin-bottom: 2rem;
    }

    label.radio {
        display: block;
        text-align: left;
        padding: 0.5rem;
        margin-left: 0rem;
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: bold;
        border: 1px solid white;
        border-radius: 5px;
    }

    label.radio input {
        margin-right: 10px;
    }

    .timer-container{
        // border: 1px solid white;
        width: 50%;
        // margin: auto;  //center align the div
        float: right;
        text-align: right;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    .button-container{
        padding: 1rem;
        width: 400px;
        background-color: rgba(0, 0, 0, 0.8);
        position: relative;
    }
}