@import url(https://fonts.googleapis.com/css?family=Quicksand&display=swap);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

#card{background-image:url(/static/media/background.9c3a4fbc.jpeg);background-size:cover;background-position:center;display:-webkit-flex;display:flex;-webkit-justify-content:space-around;justify-content:space-around;height:100vh;width:100vw}#card .question{background-color:rgba(0,0,0,0.8);color:white;border-radius:5px;margin-top:50px;padding-left:2rem;padding-right:2rem;height:400px;width:400px}#card .question_number{color:white;font-size:small}#card h2{text-align:center;font-weight:bold;font-size:1.5rem;color:white;padding-top:1.5rem;padding-bottom:1rem}#card .radio-container{background-color:rgba(61,59,59,0.7);margin-bottom:2rem}#card label.radio{display:block;text-align:left;padding:0.5rem;margin-left:0rem;margin-bottom:5px;font-size:20px;font-weight:bold;border:1px solid white;border-radius:5px}#card label.radio input{margin-right:10px}#card .timer-container{width:50%;float:right;text-align:right;padding-top:0.25rem;padding-bottom:0.25rem}#card .button-container{padding:1rem;width:400px;background-color:rgba(0,0,0,0.8);position:relative}

*{padding:0;margin:0}body{font-family:'Quicksand', sans-serif}#home{background-image:url(/static/media/background.9c3a4fbc.jpeg);background-size:cover;background-position:center;display:-webkit-flex;display:flex;-webkit-justify-content:space-around;justify-content:space-around;height:100vh;width:100vw}#home section{background-color:rgba(0,0,0,0.8);border-radius:5px;margin-top:50px;padding-left:2rem;padding-right:2rem;height:500px;width:480px}#home .cube{font-size:8rem;color:orange}#home h1{font-size:3rem;font-weight:bold;text-align:center;color:white;padding-bottom:2rem}#home .play-button-container{width:100%}#home a.play-button{background-color:green;border-radius:30px;color:white;font-size:2rem;font-weight:bold;text-decoration:none;display:block;cursor:pointer;padding:1rem;text-align:center;margin-top:2rem;transition:0.3s linear all}#home a.play-button:hover{background-color:#00e600;box-shadow:0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)}@media (max-width: 768px){#home section{padding:0, 1rem;width:100%;height:100%}}

*{padding:0;margin:0}#result-card{background-image:url(/static/media/background.9c3a4fbc.jpeg);background-size:cover;background-position:center;display:-webkit-flex;display:flex;-webkit-justify-content:space-around;justify-content:space-around;height:100vh;width:100vw}#result-card section{background-color:rgba(0,0,0,0.8);color:white;border-radius:5px;margin-top:50px;padding-left:2rem;padding-right:2rem;height:500px;width:480px}#result-card h2{text-align:center;font-weight:bold;font-size:2.5rem;color:white;padding-top:2rem;padding-bottom:2rem}#result-card p{text-align:center;font-size:2rem;font-weight:bold;color:yellow}#result-card .percent-result{color:white;font-size:1.5rem;margin:1.5rem}#result-card .timer-result{color:grey;font-size:1.5rem;margin-bottom:3rem}#result-card .end-button-container{width:100%;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between}#result-card .end-buttons{color:#fff;cursor:pointer;border-radius:30px;padding:1rem;text-align:center;text-decoration:none;transition:0.3s linear all;width:180px;margin-top:3rem}#result-card #check-answer{background-color:#7d2fe4;font-weight:bold;padding:25px}#result-card #check-answer:hover{background-color:#b589f0;box-shadow:0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)}#result-card #end-game{background-color:blue;font-weight:bold;padding:25px}#result-card #end-game:hover{background-color:#66f;box-shadow:0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)}

#select-questions{background-image:url(/static/media/background.9c3a4fbc.jpeg);background-size:cover;background-position:center;display:-webkit-flex;display:flex;-webkit-justify-content:space-around;justify-content:space-around;height:100vh;width:100vw}#select-questions section{background-color:rgba(0,0,0,0.8);border-radius:5px;margin-top:50px;padding-left:2rem;padding-right:2rem;height:500px;width:480px}#select-questions h1{font-size:2.5rem;font-weight:bold;text-align:center;color:white;padding-top:1rem;padding-bottom:1rem}#select-questions .select-button-container{width:100%}#select-questions a.select-button{background-color:green;border-radius:30px;color:white;font-size:1.5rem;font-weight:bold;text-decoration:none;display:block;cursor:pointer;padding:0.5rem;text-align:center;margin-top:2rem;transition:0.3s linear all}#select-questions a.select-button:hover{background-color:#00e600;box-shadow:0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)}

